import React from 'react'
import Img from 'gatsby-image'
import './activity-page-top.css'
import LogoMain from '../logo-main'




const ActivityPageTop = ({ title, logos, slogan, illustration,  }) => {

  let activityNum = 0
  if(title.includes('Day')) {
    activityNum = 0
  } else if(title.includes('Con')) {
    activityNum = 1
  } else if(title.includes('Table')) {
    activityNum = 2
  } else if(title.includes('Box')) {
    activityNum = 3
  } else if(title.includes('Picks')) {
    activityNum = 4
  }
  console.log(activityNum%2)
  const activityPageLogo = logos.edges[activityNum * 2].node.publicURL
  const activityPageLogoSrc = logos.edges[(activityNum * 2) + 1].node.publicURL


  return (
    <div className='activity-page-top-wrapper'>
      <LogoMain />

      <div className='activity-page-top-container'>
        <div className='activity-page-title-logo-container'>
          {/*<Img
            className='activity-page-logo'
            fluid={logo.fluid}
            alt="Logo"
          />*/}
          <div className="activity-page-logo">
            <img src={activityPageLogo} alt="logo"/>
          </div>

          <div className="activity-page-logo-text">
            <img src={activityPageLogoSrc} alt="logo"/>
          </div>
          <div className='activity-page-slogan'>{slogan}</div>
        </div>

        <div className='activity-page-illust'>
          <Img
            fluid={illustration.fluid}
            alt="Illustration"
          />
        </div>
      </div>

    </div>
  );
}

export default ActivityPageTop
