import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BlogPostList from '../components/blog-post-list'
import ActivityPageTop from '../components/activity-page/activity-page-top'
import EmailSubscription from '../components/email-subscription'
import Footer from '../components/footer'
import Divider from '../components/divider'
import { isAndroid, isIOS } from 'react-device-detect'




class ActivityPage extends Component {

  render() {
    const {
      slug,
      title,
      slogan,
      logo,
      logoText,
      illustration
    } = this.props.data.contentfulActivityPage

    let device = 'desktop'
    if(isIOS) {
      device = 'ios'
    } else if(isAndroid) {
      device = 'android'
    }

    return (
      <>
        <Helmet
          meta={[
            { name: 'title', content: title },
            { name: 'apple-itunes-app', content: 'app-id=1218633352' },
            { property: 'og:type', content: "website" },
            { property: 'og:url', content: "https://designspectrum.org/" + slug },
            { property: 'og:title', content: title },
            { property: 'og:description', content: slogan },
            { property: 'og:image', content: "https:" + logo.fluid.src },
          ]}
        >
          <title>{title}</title>
        </Helmet>
        <Layout>
          <ActivityPageTop
            title={title}
            logos={this.props.data.allFile}
            slogan={slogan}
            illustration={illustration}
          />

          <BlogPostList
            title={title}
            data={this.props.data.allContentfulBlogPost}
            device={device}
          />

          <Divider />
          <EmailSubscription />
          <Divider />

        </Layout>
        <Footer />
      </>
    );
  }
}

export default ActivityPage

export const pageQuery = graphql`
  query activityPageQuery($slug: String!) {
    contentfulActivityPage(slug: { eq: $slug }) {
      contentful_id
      slug
      title
      slogan
      logo {
        fluid(maxWidth: 140, maxHeight: 140) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      illustration {
        fluid(maxWidth: 540, maxHeight: 270) {
          ...GatsbyContentfulFluid
        }
      }
    },
    allContentfulBlogPost (
    sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          contentful_id
          slug
          activityType
          numbering
          title
          speaker
          linkTo
          heroImage {
            fluid(maxWidth: 384, maxHeight: 384) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    },
    allFile(
      filter: { name: { regex: "/activity-[1-5]$|[^0|^m]-text$/" } },
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`
